import React, { useState } from "react";
import Input from "./Input";
import Select from "./Select";
import Input2 from "./Input2";
import SearchBtn from "./SearchBtn";
import { UilSearch, UilEstate } from "@iconscout/react-unicons";

interface Props {
  onData: (data: Search) => void;
}

interface Search {
  location: string;
  propertyType: string;
  transactionType: string;
  priceFrom: number;
  priceTo: number;
  surfaceFrom: number;
  surfaceTo: number;
}

const Search = ({ onData }: Props) => {
  const [activeSearch, setActiveSearch] = useState<boolean>(true);
  const [location, setLocation] = useState<string>("");
  const [propertyType, setPropertyType] = useState<string>("Wszystkie oferty");
  const [transactionType, setTransactionType] =
    useState<string>("Sprzedaż i wynajem");
  const [priceFrom, setPriceFrom] = useState<string>("");
  const [priceTo, setPriceTo] = useState<string>("");
  const [surfaceFrom, setSurfaceFrom] = useState<string>("");
  const [surfaceTo, setSurfaceTo] = useState<string>("");

  const handleSearch = () => {
    const searchParams: Search = {
      location,
      propertyType,
      transactionType,
      priceFrom: priceFrom ? parseFloat(priceFrom) : 0,
      priceTo: priceTo ? parseFloat(priceTo) : Infinity,
      surfaceFrom: surfaceFrom ? parseFloat(surfaceFrom) : 0,
      surfaceTo: surfaceTo ? parseFloat(surfaceTo) : Infinity,
    };
    onData(searchParams);
  };

  return (
    <div className="w-[320px] h-[540px] rounded-[20px] border-2 p-[10px] border-accentYellow bg-black mb-[24px] shadow-[0px_0px_10px_2px] shadow-[rgba(255,166,0,0.25)]">
      <div className="w-full h-full flex flex-col text-white font-['Rubik']">
        <div className="w-full h-[30px] flex flex-row items-center relative">
          <div
            className={
              activeSearch
                ? "aspect-square w-[30px] flex justify-center items-center text-center rounded-t-[10px] cursor-pointer bg-thirdPannelBackground"
                : "aspect-square w-[30px] flex justify-center items-center text-center rounded-t-[10px] cursor-pointer bg-black"
            }
            onClick={() => {
              setActiveSearch(true);
            }}
          >
            {activeSearch ? (
              <UilSearch size="20" color="#A6A6A6" />
            ) : (
              <UilSearch size="20" color="#4A4A4A" />
            )}
          </div>
          <div className="absolute left-[30px] aspect-square w-[30px] flex justify-center items-center text-center rounded-bl-[10px] bg-black z-10"></div>
          <div className="absolute left-[30px] aspect-square w-[30px] flex justify-center items-center text-center bg-thirdPannelBackground z-1"></div>
          {/* <div
            className={
              activeSearch
                ? "aspect-square w-[30px] flex justify-center items-center text-center rounded-t-[10px] cursor-pointer bg-black"
                : "aspect-square w-[30px] flex justify-center items-center text-center rounded-t-[10px] cursor-pointer bg-thirdPannelBackground"
            }
            onClick={() => {
              setActiveSearch(false);
            }}
          >
            {activeSearch ? (
              <UilEstate size="20" color="#4A4A4A" />
            ) : (
              <UilEstate size="20" color="#A6A6A6" />
            )}
          </div> */}
        </div>
        <div
          className={
            activeSearch
              ? "h-full w-full flex flex-col justify-center bg-thirdPannelBackground rounded-[10px] rounded-tl-none p-[10px] pb-[5px]"
              : "h-full w-full flex flex-col justify-center bg-thirdPannelBackground rounded-[10px] p-[10px] pb-[5px]"
          }
        >
          <div className="block">
            <p className="font-normal text-sm leading-3">WYSZUKAJ</p>
            <p className="font-extrabold text-lg leading-6">NIERUCHOMOŚĆ</p>
          </div>
          <div className="flex h-full flex-col items-center justify-around">
            <Input
              id="location"
              type="text"
              placeholder="Lokalizacja (np.: miasto, dzielnica, ulica)"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            >
              LOKALIZACJA
            </Input>
            <Select
              id="type-of-property"
              options={[
                "Wszystkie oferty",
                "Mieszkania",
                "Domy",
                "Działki",
                "Lokale",
                "Obiekty",
              ]}
              value={propertyType}
              onChange={(e) => setPropertyType(e.target.value)}
            >
              RODZAJ NIERUCHOMOŚCI
            </Select>
            <Select
              id="type-of-transaction"
              options={["Sprzedaż i wynajem", "Sprzedaż", "Wynajem"]}
              value={transactionType}
              onChange={(e) => setTransactionType(e.target.value)}
            >
              RODZAJ TRANSAKCJI
            </Select>
            <Input2
              idFrom="cost-from"
              idTo="cost-to"
              placeholderFrom="od"
              placeholderTo="do"
              valueFrom={priceFrom}
              valueTo={priceTo}
              onChangeFrom={(e) => setPriceFrom(e.target.value)}
              onChangeTo={(e) => setPriceTo(e.target.value)}
            >
              CENA (ZŁ)
            </Input2>
            <Input2
              idFrom="surface-from"
              idTo="surface-to"
              placeholderFrom="od"
              placeholderTo="do"
              valueFrom={surfaceFrom}
              valueTo={surfaceTo}
              onChangeFrom={(e) => setSurfaceFrom(e.target.value)}
              onChangeTo={(e) => setSurfaceTo(e.target.value)}
            >
              POWIERZCHNIA (m<sup>2</sup>)
            </Input2>
            <SearchBtn onClick={handleSearch}>Szukaj</SearchBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
