import React from "react";
import Contact from "./Contact";
import Address from "./Address";

const Footer = () => {
  return (
    <div
      id="contact"
      className="w-full h-full bg-footer-linear-gradient px-[20px]"
    >
      <div className="text-white text-2xl font-['Rubik'] flex flex-col justify-center items-center">
        <div className="w-[320px] my-[25px]">
          <p className="font-normal leading-6 lg:text-center">SKONTAKTUJ SIĘ</p>
          <p className="font-extrabold leading-6 lg:text-center">Z NAMI</p>
        </div>
        <Contact />
      </div>
      <Address />
    </div>
  );
};

export default Footer;
