import React from "react";

interface Props {
  children: React.ReactNode;
  options: string[];
  id: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select = ({ children, id, options, value, onChange }: Props) => {
  return (
    <div>
      <label htmlFor={id} className="text-[15px] ">
        {children}
      </label>
      <select
        id={id}
        value={value}
        onChange={onChange}
        className="block w-[280px] h-[45px] rounded-[10px] text-[14px] p-[8px] text-black bg-[#D9D9D9]"
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
