import React from "react";

const Address = () => {
  return (
    <div className="text-white font-['Rubik']">
      <p className="font-extrabold text-2xl mt-4">WERWA</p>
      <p className="font-normal text-lg">Biuro Obrotu Nieruchomościami</p>
      <p className="font-normal text-lg">Numer licencji zawodowej 30506</p>
      <p className="font-normal text-lg mt-4">Ulica R. Lewandowskiego 1a/7</p>
      <p className="font-normal text-lg">47-420 Kuźnia Raciborska</p>
      <p className="font-normal text-lg mt-4 pb-4">Tel. 792 343 936</p>
    </div>
  );
};

export default Address;
