import React, { useState, useEffect } from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
} from "firebase/firestore";
import { db, storage } from "../firebase"; // Import storage from your firebase config
import { getDownloadURL, ref } from "firebase/storage"; // Import necessary functions from Firebase Storage
import OfferBtn from "./OfferBtn";
import Search from "./Search";
import { UilPrevious, UilStepForward } from "@iconscout/react-unicons";
import EstateSite from "./EstateSite";

interface Estate {
  id: string;
  name: string;
  location: string;
  cost: string;
  description: string;
  roomCount: string;
  surface: string;
  mainImageUrl: string;
  type:
    | "Dom na Sprzedaż"
    | "Dom na Wynajem"
    | "Mieszkanie na Sprzedaż"
    | "Mieszkanie na Wynajem"
    | "Działka na Sprzedaż"
    | "Działka na Wynajem"
    | "Lokal na Sprzedaż"
    | "Lokal na Wynajem"
    | "Obiekt na Sprzedaż"
    | "Obiekt na Wynajem";
  timestamp: Timestamp;
}

interface MainProps {
  filter: "Najnowsze" | "Sprzedaż" | "Wynajem" | "Kalkulator";
  showAll: boolean;
  estateNotClicked: boolean;
  setEstateNotClicked: (state: boolean) => void;
}

const Main: React.FC<MainProps> = ({
  filter,
  showAll,
  estateNotClicked,
  setEstateNotClicked,
}) => {
  const [searchData, setSearchData] = useState<Search | null>(null);
  const [estates, setEstates] = useState<Estate[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [estateClicked, setEstateClicked] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    const estatesCollection = collection(db, "estates");
    const q = query(estatesCollection, orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchEstates = async () => {
        const estatesList = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const estateData = doc.data() as Estate;
            const { id: estateId, ...restEstateData } = estateData;
            try {
              const mainImageRef = ref(storage, estateData.mainImageUrl);
              const mainImageUrl = await getDownloadURL(mainImageRef);
              return { id: doc.id, ...restEstateData, mainImageUrl };
            } catch (error) {
              console.error("Error fetching mainImageUrl:", error);
              return {
                id: doc.id,
                ...restEstateData,
                mainImageUrl: "defaultImageUrl.png",
              };
            }
          })
        );
        setEstates(estatesList);
        setLoading(false);
      };
      fetchEstates();
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (estateNotClicked) {
      setEstateClicked(null);
      setEstateNotClicked(false);
    }
  });

  const handleSearchData = (data: Search) => {
    setCurrentPage(1);
    setSearchData(data);
  };

  const resetFilters = () => {
    setSearchData(null); // Resetuje dane wyszukiwania
    setCurrentPage(1); // Ustawia aktualną stronę na 1
  };

  useEffect(() => {
    resetFilters(); // Zresetowanie stanu filtrów po zmianie filtra
  }, [filter]);

  // Filter estates based on selected filter
  const filteredEstates = estates.filter((estate) => {
    let matchesFilter = true;

    // Filter by transaction type (Sprzedaż/Wynajem)
    switch (filter) {
      case "Sprzedaż":
        matchesFilter = estate.type.includes("Sprzedaż");
        break;
      case "Wynajem":
        matchesFilter = estate.type.includes("Wynajem");
        break;
      default:
        matchesFilter = true;
    }

    if (searchData) {
      const {
        location,
        propertyType,
        transactionType,
        priceFrom,
        priceTo,
        surfaceFrom,
        surfaceTo,
      } = searchData;

      if (propertyType !== "" && transactionType !== "") {
        switch (transactionType) {
          case "Sprzedaż i wynajem":
            switch (propertyType) {
              case "Wszystkie oferty":
                location !== ""
                  ? (matchesFilter = estate.location
                      .toLocaleLowerCase()
                      .includes(location.toLowerCase()))
                  : (matchesFilter = true);
                break;
              case "Mieszkania":
                estate.type === "Mieszkanie na Sprzedaż" ||
                estate.type === "Mieszkanie na Wynajem"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Domy":
                estate.type === "Dom na Sprzedaż" ||
                estate.type === "Dom na Wynajem"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Działki":
                estate.type === "Działka na Sprzedaż" ||
                estate.type === "Działka na Wynajem"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Lokale":
                estate.type === "Lokal na Sprzedaż" ||
                estate.type === "Lokal na Wynajem"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Obiekty":
                estate.type === "Obiekt na Sprzedaż" ||
                estate.type === "Obiekt na Wynajem"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
            }
            break;
          case "Sprzedaż":
            switch (propertyType) {
              case "Wszystkie oferty":
                estate.type.includes("Sprzedaż")
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Mieszkania":
                estate.type === "Mieszkanie na Sprzedaż"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Domy":
                estate.type === "Dom na Sprzedaż"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Działki":
                estate.type === "Działka na Sprzedaż"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Lokale":
                estate.type === "Lokal na Sprzedaż"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Obiekty":
                estate.type === "Obiekt na Sprzedaż"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
            }
            break;
          case "Wynajem":
            switch (propertyType) {
              case "Wszystkie oferty":
                estate.type.includes("Wynajem")
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Mieszkania":
                estate.type === "Mieszkanie na Wynajem"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Domy":
                estate.type === "Dom na Wynajem"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Działki":
                estate.type === "Działka na Wynajem"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Lokale":
                estate.type === "Lokal na Wynajem"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
              case "Obiekty":
                estate.type === "Obiekt na Wynajem"
                  ? location !== ""
                    ? (matchesFilter = estate.location
                        .toLocaleLowerCase()
                        .includes(location.toLowerCase()))
                    : (matchesFilter = true)
                  : (matchesFilter = false);
                break;
            }
        }
      }

      const estatePrice =
        typeof estate.cost === "string"
          ? parseFloat(estate.cost.replace(/\D/g, ""))
          : estate.cost;

      if (estatePrice < priceFrom || estatePrice > priceTo) {
        matchesFilter = false;
      }

      // Convert estate surface to a number for comparison
      const estateSurface =
        typeof estate.surface === "string"
          ? parseFloat(estate.surface.replace(/\D/g, "")) // If it's a string, remove non-digit characters
          : estate.surface; // If it's already a number, use it directly

      if (estateSurface < surfaceFrom || estateSurface > surfaceTo) {
        matchesFilter = false;
      }
    }

    return matchesFilter;
  });

  let itemsPerPage;
  const width = window.innerWidth;

  if (width >= 1200) {
    itemsPerPage = 6; // Large screen
  } else if (width >= 992) {
    itemsPerPage = 4; // Medium screen
  } else if (width >= 768) {
    itemsPerPage = 2; // Tablet view
  } else {
    itemsPerPage = 3; // Mobile view
  }

  const totalPages = Math.ceil(filteredEstates.length / itemsPerPage);
  const paginatedEstates = filteredEstates.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <div className="overflow-hidden h-[30vh] md:h-[50vh] flex flex-col justify-center items-center">
        <h1 className="text-white z-10 text-[85px] leading-[55px] md:text-[128px] md:leading-[90px] drop-shadow-[0px_0px_10px_#000000] font-bold font-['Rubik']">
          WERWA
        </h1>
        <h2 className="text-white z-10 text-[35px] leading-[55px] md:text-[48px] md:leading-[90px] drop-shadow-[0px_0px_10px_#000000] font-bold font-['Rubik']">
          NIERUCHOMOŚĆI
        </h2>
        <img
          src={`${process.env.PUBLIC_URL}/background.webp`}
          alt="background-image"
          className="absolute w-[100vw] z-0"
        />
      </div>
      {estateClicked ? (
        <EstateSite estateId={estateClicked} />
      ) : (
        <div className="w-full min-h-[90dvh] bg-radial-gradient px-[44px] py-[15px] pb-[100px] md:pb-0 md:px-[78px] md:py-[40px] lg:px-[50px] xl:py-[52px] relative shadow-[0px_0px_20px_rgba(0,0,0,1)]">
          <div className="flex flex-col-reverse justify-evenly items-center md:flex-row md:justify-between 2xl:justify-evenly relative">
            <div className="flex flex-col relative lg:grid lg:grid-rows-2 lg:grid-cols-2 xl:grid-rows-2 xl:grid-cols-3 xl:mt-10">
              <div className="text-white text-2xl font-['Rubik'] mb-[15px] lg:absolute lg:top-[-50px] xl:top-[-60px]">
                <p className="font-normal leading-6">{filter}</p>
                <p className="font-extrabold leading-6">Oferty</p>
              </div>
              {!showAll
                ? paginatedEstates.map((estate) => (
                    <OfferBtn
                      onClick={() => {
                        setEstateClicked(estate.id);
                      }}
                      mainImage={estate.mainImageUrl}
                      key={estate.id}
                      offerName={estate.location}
                      offerType={estate.type}
                      price={estate.cost}
                      surface={estate.surface}
                      location={estate.location}
                      roomCount={estate.roomCount}
                    />
                  ))
                : paginatedEstates.map((estate) => (
                    <OfferBtn
                      onClick={() => {
                        setEstateClicked(estate.id);
                      }}
                      mainImage={estate.mainImageUrl}
                      key={estate.id}
                      offerName={estate.location}
                      offerType={estate.type}
                      price={estate.cost}
                      surface={estate.surface}
                      location={estate.location}
                      roomCount={estate.roomCount}
                    />
                  ))}
              {totalPages > 1 && (
                <div className="flex justify-center items-center mt-6 absolute bottom-[-50px] left-1/2 -translate-x-1/2">
                  <button
                    className="p-2 text-white bg-secondaryPannelBackground rounded-l disabled:text-gray-300 disabled:bg-thirdPannelBackground flex justify-center items-center"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    {"<"}
                  </button>
                  <span className="px-4 py-2 text-white w-[180px] md:w-[140px] mx-[1px] text-center bg-secondaryPannelBackground">
                    Strona {currentPage} z {totalPages}
                  </span>
                  <button
                    className="p-2 text-white bg-secondaryPannelBackground rounded-r disabled:text-gray-300 disabled:bg-thirdPannelBackground flex justify-center items-center"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    {">"}
                  </button>
                </div>
              )}
            </div>
            {filter === "Najnowsze" && <Search onData={handleSearchData} />}
          </div>
        </div>
      )}
    </>
  );
};

export default Main;
