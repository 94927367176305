import { db, collection, addDoc } from "./firebase";

interface Message {
  name: string;
  email: string;
  tel: string;
  message: string;
  timestamp: Date;
}

export const saveMessage = async (message: Message) => {
  try {
    const docRef = await addDoc(collection(db, "messages"), message);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};
