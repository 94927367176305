import React from "react";

interface Props {
  children: string;
  onClick: () => void;
}

const SearchBtn = ({ children, onClick }: Props) => {
  return (
    <div
      onClick={onClick}
      className="w-[140px] h-[45px] m-[10px] bg-accentYellow rounded-[10px] flex flex-row justify-center items-center text-center text-lg cursor-pointer"
    >
      {children}
    </div>
  );
};

export default SearchBtn;
