import React, { useState } from "react";
import Main from "./Main";
import Nav from "./Nav";
import Calculator from "./Calculator";

const Header = () => {
  // const [filter, setFilter] = useState<
  //   "Najnowsze" | "Sprzedaż" | "Wynajem" | "Kalkulator"
  // >("Najnowsze");

  const [filter, setFilter] = useState<
    "Najnowsze" | "Sprzedaż" | "Wynajem" | "Kalkulator"
  >("Najnowsze");
  const [showAll, setShowAll] = useState<boolean>(false);
  const [estateNotClicked, setEstateNotClicked] = useState<boolean>(true);
  const [main, setMain] = useState<boolean>(true);

  return (
    <>
      <div className="header w-full border-b-2 border-solid border-accentYellow bg-black shadow-md shadow-[rgba(255,166,0,0.25)] h-[60px] p-[5px] md:h-[80px] md:p-[12px] xl:p-[7px] flex relative z-50">
        <img
          onClick={() => {
            setFilter("Najnowsze");
            setShowAll(true);
            setEstateNotClicked(true);
          }}
          src={`${process.env.PUBLIC_URL}/logo.webp`}
          alt="Logo"
          className="aspect-square h-[49px] md:h-[106px] xl:h-[117px] cursor-pointer"
        />
        <div className="text-white hidden ml-auto font-['Rubik'] md:flex md:items-center md:text-2xl md:mr-[20px]">
          <p>Tel. 792 343 936</p>
        </div>
      </div>
      <Nav
        setFilter={setFilter}
        setShowAll={setShowAll}
        setEstateNotClicked={setEstateNotClicked}
      />
      {filter !== "Kalkulator" ? (
        <Main
          filter={filter}
          showAll={showAll}
          estateNotClicked={estateNotClicked}
          setEstateNotClicked={setEstateNotClicked}
        />
      ) : (
        <Calculator />
      )}
    </>
  );
};

export default Header;
