import React, { useState } from "react";
import Input from "./Input";
import Textarea from "./TextArea";
import { saveMessage } from "../messageService";
import SearchBtn from "./SearchBtn";

interface Message {
  name: string;
  email: string;
  tel: string;
  message: string;
  timestamp: Date;
}

const Contact: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [tel, setTel] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const handleSend: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!name || !email || !message) {
      alert("Wypełnij wszystkie wymagane pola.");
      return;
    }

    const data: Message = {
      name,
      email,
      tel,
      message,
      timestamp: new Date(),
    };

    try {
      await saveMessage(data);
      alert("Wiadomość została zapisana!");
      setName("");
      setEmail("");
      setTel("");
      setMessage("");
    } catch (error) {
      console.error("Error saving message:", error);
      alert("Wystąpił błąd podczas zapisywania wiadomości.");
    }
  };

  const handleButtonClick = () => {
    const form = document.querySelector("form");
    if (form) {
      handleSend({
        preventDefault: () => {},
      } as React.FormEvent<HTMLFormElement>);
    }
  };

  return (
    <div className="w-[320px] p-[10px] bg-secondaryPannelBackground rounded-[20px] flex flex-col items-center justify-around lg:w-[610px] lg:flex lg:flex-col">
      <form onSubmit={handleSend}>
        <div className="flex flex-col items-center justify-around lg:hidden">
          <Input
            id="name-and-surname"
            type="text"
            placeholder="Imię i Nazwisko"
            value={name}
            onChange={(e) => setName(e.target.value)}
          >
            IMIĘ I NAZWISKO
          </Input>
          <Input
            id="email"
            type="email"
            placeholder="Adres Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          >
            EMAIL
          </Input>
          <Input
            id="tel"
            type="tel"
            placeholder="Telefon Kontaktowy"
            value={tel}
            onChange={(e) => setTel(e.target.value)}
          >
            TELEFON KONTAKTOWY
          </Input>
          <Textarea
            id="message"
            placeholder="Wiadomość"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          >
            WIADOMOŚĆ
          </Textarea>
          <SearchBtn onClick={handleButtonClick}>WYŚLIJ</SearchBtn>
        </div>

        <div className="hidden lg:block">
          <div className="flex flex-row">
            <Input
              id="name-and-surname"
              type="text"
              placeholder="Imię i Nazwisko"
              value={name}
              onChange={(e) => setName(e.target.value)}
            >
              IMIĘ I NAZWISKO
            </Input>
            <Input
              id="email"
              type="email"
              placeholder="Adres Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            >
              EMAIL
            </Input>
          </div>

          <div className="flex flex-row">
            <Textarea
              id="message"
              placeholder="Wiadomość"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            >
              WIADOMOŚĆ
            </Textarea>
            <div className="flex flex-col justify-between items-center">
              <Input
                id="tel"
                type="tel"
                placeholder="Telefon Kontaktowy"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
              >
                TELEFON KONTAKTOWY
              </Input>
              <SearchBtn onClick={handleButtonClick}>WYŚLIJ</SearchBtn>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Contact;
