import React, { useState, useEffect } from "react";
import OfferBtn from "./OfferBtn";
import Search from "./Search";

const Calculator = () => {
  return (
    <div className="w-full min-h-[90dvh] bg-radial-gradient px-[44px] py-[15px] md:px-[78px] md:py-[40px] lg:px-[50px] xl:py-[52px] relative">
      <div className="flex flex-col-reverse justify-evenly items-center md:flex-row md:justify-between 2xl:justify-evenly relative">
        <div className="flex flex-col md:hidden"></div>
      </div>
    </div>
  );
};

export default Calculator;
