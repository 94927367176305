import React from "react";

interface Props {
  mainImage: string;
  offerName: string;
  price: string;
  surface: string;
  location: string;
  roomCount: string;
  onClick: () => void;
  offerType:
    | "Dom na Sprzedaż"
    | "Dom na Wynajem"
    | "Mieszkanie na Sprzedaż"
    | "Mieszkanie na Wynajem"
    | "Działka na Sprzedaż"
    | "Działka na Wynajem"
    | "Lokal na Sprzedaż"
    | "Lokal na Wynajem"
    | "Obiekt na Sprzedaż"
    | "Obiekt na Wynajem";
}

const OfferBtn = ({
  mainImage,
  offerType,
  price,
  surface,
  location,
  roomCount,
  onClick,
}: Props) => {
  return (
    <button
      onClick={onClick}
      className="w-[272px] h-[233px] bg-black rounded-[20px] p-[10px] m-[10px] shadow-lg shadow-[rgba(0,0,0,0.5)]"
    >
      <div className="w-full h-full bg-secondaryPannelBackground rounded-[10px] border-2 border-solid border-accentYellow relative">
        <div
          className="w-full h-[158px] border-solid border-b-2 rounded-t-[7px] border-accentYellow relative"
          style={{
            backgroundImage: `url("${mainImage}")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute bottom-[-2px] left-1/2 -translate-x-1/2 w-[162px] h-[22px] bg-secondaryPannelBackground border-solid border-x-2 border-t-2 rounded-t-[20px] border-accentYellow pt-[3px]">
            <p className="text-center text-white leading-3 text-[14px] font-bold">
              {price.toLocaleString() + " zł"}
            </p>
            <p className="text-center leading-3 text-[10px] font-normal text-[#a6a6a6]">
              {price && surface
                ? `${(parseFloat(price) / parseFloat(surface)).toFixed(
                    2
                  )} zł/m²`
                : "N/A"}
            </p>
          </div>
        </div>
        <div className="h-[50px] w-full flex flex-col justify-center">
          <p className="text-left text-white mx-[5px] font-bold leading-4 truncate">
            {location}
          </p>
          <p className="text-left mx-[5px] leading-4 text-[#a6a6a6] text-[10px] truncate">
            {offerType}
          </p>
          <p className="absolute text-left mx-[5px] leading-4 text-[#a6a6a6] text-[10px] right-0 bottom-0">
            {parseInt(roomCount) != 0
              ? roomCount +
                (parseInt(roomCount) >= 5 ? " Pokoi" : " Pokoje") +
                ` | ${surface} m²`
              : surface + "m²"}
          </p>
        </div>
      </div>
    </button>
  );
};

export default OfferBtn;
