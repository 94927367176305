import React from "react";

interface Props {
  children: React.ReactNode;
  type:
    | "text"
    | "number"
    | "email"
    | "tel"
    | "radio"
    | "checkbox"
    | "range"
    | "date";
  id: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = ({ children, type, id, placeholder, value, onChange }: Props) => {
  return (
    <div className="flex flex-col m-[5px]">
      <label htmlFor={id} className="text-[15px] leading-5">
        {children}
      </label>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="block w-[280px] h-[45px] rounded-[10px] text-[14px] p-[8px] text-black bg-[#D9D9D9] placeholder:text-[#797979]"
      ></input>
    </div>
  );
};

export default Input;
