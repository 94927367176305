import React from "react";

interface Props {
  children: React.ReactNode;
  idFrom: string;
  idTo: string;
  placeholderFrom: string;
  placeholderTo: string;
  valueFrom: string;
  valueTo: string;
  onChangeFrom: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeTo: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input2 = ({
  children,
  idFrom,
  idTo,
  placeholderFrom,
  placeholderTo,
  valueFrom,
  valueTo,
  onChangeFrom,
  onChangeTo,
}: Props) => {
  return (
    <div className="flex flex-col m-[5px] ">
      <label htmlFor={idFrom} className="text-[15px] leading-5 ">
        {children}
      </label>
      <div className="flex w-[280px] h-[45px]">
        <input
          id={idFrom}
          type="text"
          placeholder={placeholderFrom}
          value={valueFrom}
          onChange={onChangeFrom}
          className="block w-1/2 rounded-r-none rounded-[10px] text-[14px] p-[8px] border-r-[1px] border-[#AFAFAF] text-black bg-[#D9D9D9] placeholder:text-[#797979]"
        />
        <input
          id={idTo}
          type="text"
          placeholder={placeholderTo}
          value={valueTo}
          onChange={onChangeTo}
          className="block w-1/2 rounded-l-none rounded-[10px] text-[14px] p-[8px] border-l-[1px] border-[#AFAFAF] text-black bg-[#D9D9D9] placeholder:text-[#797979]"
        />
      </div>
    </div>
  );
};

export default Input2;
