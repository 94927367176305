import React, { useState } from "react";

interface NavProps {
  setFilter: (
    filter: "Najnowsze" | "Sprzedaż" | "Wynajem" | "Kalkulator"
  ) => void;
  setShowAll: (showAll: boolean) => void;
  setEstateNotClicked: (state: boolean) => void;
}

const Nav: React.FC<NavProps> = ({
  setFilter,
  setShowAll,
  setEstateNotClicked,
}) => {
  const [activeFilter, setActiveFilter] = useState<
    "Najnowsze" | "Sprzedaż" | "Wynajem" | "Kalkulator"
  >("Najnowsze");

  const handleFilterChange = (
    filter: "Najnowsze" | "Sprzedaż" | "Wynajem" | "Kalkulator"
  ) => {
    setEstateNotClicked(true);
    setFilter(filter);
    setActiveFilter(filter);
    if (filter === "Najnowsze") {
      setShowAll(false);
    } else {
      setShowAll(true);
    }
  };

  return (
    <div className="nav w-full bg-black h-[50px] hidden md:p-[12px] xl:p-[7px] md:flex md:flex-row md:justify-end md:items-center shadow-lg shadow-[rgba(0,0,0,0.5)] relative z-30">
      <div className="md:w-[calc(100vw-106px-36px)] xl:w-[calc(100vw-117px-21px)] text-white flex flex-row justify-evenly items-center font-['Rubik'] text-lg">
        <p
          onClick={() => handleFilterChange("Najnowsze")}
          className={`cursor-pointer ${
            activeFilter === "Najnowsze"
              ? "drop-shadow-[0px_0px_10px_#ffffff] rounded-[10px]"
              : ""
          } px-2 py-1 transition-all ease-in-out duration-300 hover:-translate-y-0.5`}
        >
          Strona Główna
        </p>
        <p
          onClick={() => handleFilterChange("Sprzedaż")}
          className={`cursor-pointer ${
            activeFilter === "Sprzedaż"
              ? "drop-shadow-[0px_0px_10px_#ffffff] rounded-[10px]"
              : ""
          } px-2 py-1 transition-all ease-in-out duration-300 hover:-translate-y-0.5`}
        >
          Sprzedaż
        </p>
        <p
          onClick={() => handleFilterChange("Wynajem")}
          className={`cursor-pointer ${
            activeFilter === "Wynajem"
              ? "drop-shadow-[0px_0px_10px_#ffffff] rounded-[10px]"
              : ""
          } px-2 py-1 transition-all ease-in-out duration-300 hover:-translate-y-0.5`}
        >
          Najem
        </p>
        {/* <p
          onClick={() => handleFilterChange("Kalkulator")}
          className={`cursor-pointer ${
            activeFilter === "Kalkulator"
              ? "drop-shadow-[0px_0px_10px_#ffffff] rounded-[10px]"
              : ""
          } px-2 py-1 transition-all ease-in-out duration-300 hover:-translate-y-0.5`}
        >
          Kalkulator
        </p> */}
        <a
          href="#contact"
          className="cursor-pointer px-2 py-1 rounded transition-all ease-in-out duration-300 hover:-translate-y-0.5 hover:drop-shadow-[0px_0px_10px_#ffffff]"
        >
          Kontakt
        </a>
      </div>
    </div>
  );
};

export default Nav;
