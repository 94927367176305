import React from "react";

interface Props {
  children: React.ReactNode;
  id: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea = ({ children, id, placeholder, value, onChange }: Props) => {
  return (
    <div className="flex flex-col m-[5px]">
      <label htmlFor={id} className="text-[15px] leading-5">
        {children}
      </label>
      <textarea
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="block w-[280px] h-full rounded-[10px] text-[14px] p-[8px] text-black bg-[#D9D9D9] placeholder:text-[#797979] leading-5"
      ></textarea>
    </div>
  );
};

export default TextArea;
